import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

import AppLanguage from '../common/AppLanguage';

export type Data = {
  language: AppLanguage;
  telegramUserId: number;
  tripId: string;
};

export interface Result {
  expiration_date: number;
  id: string;
}

export default function useTelegramPrepareTripMessage() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'telegram-prepareTripMessage'), [functions]);
}
