import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import AppLanguage from '../AppLanguage';
import { StoragePicture } from '../StoragePicture';
import { UnsplashPicture } from '../UnsplashPicture';
import { type DestinationDoc } from './Destinations';
import { type ProfileDoc } from './Profiles';

export enum TripStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface TripDoc {
  _v: 1;
  checkoutSessionUrl?: string;
  createdAt: Timestamp;
  description: string;
  destinationRef: DocumentReference<DestinationDoc>;
  endsAt: Timestamp;
  femaleParticipantPlaces: number;
  femaleParticipantPrice: { amount: number, currency: 'EUR' | 'PLN'; };
  femaleParticipants: DocumentReference<ProfileDoc>[];
  femaleParticipantTicketCoverage: number;
  indexedAt?: Timestamp;
  instagramTag?: string;
  maleParticipantPlaces: number;
  maleParticipantPrice: { amount: number, currency: 'EUR' | 'PLN'; };
  maleParticipants: DocumentReference<ProfileDoc>[];
  name: string;
  organizerRef: DocumentReference<ProfileDoc>;
  picture: UnsplashPicture;
  pictures: StoragePicture[];
  publishedAt?: Timestamp;
  startsAt: Timestamp;
  status: TripStatus;
  translations?: Record<AppLanguage, Pick<TripDoc, 'description' | 'name'>>;
  unpublishedAt?: Timestamp;
  updatedAt: Timestamp;
}

export const isTripDoc = (
  data?: DocumentData,
): data is TripDoc => true;

export const isTripRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TripDoc> => ref.parent.id === 'trips';

function isTripSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<TripDoc>;
function isTripSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TripDoc>;
function isTripSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<TripDoc> | QueryDocumentSnapshot<TripDoc> {
  return isTripRef(snap.ref);
}

export { isTripSnap };

export const getTripsCollectionRef = (firestore: Firestore) => collection(firestore, 'trips') as CollectionReference<TripDoc>;

export const useTripsCollectionRef = () => {
  const firestore = useFirestore();
  return getTripsCollectionRef(firestore);
};
